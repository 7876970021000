.container {
  /* max-width: 80vw; */
  min-height: 100vh;
  margin: 80px auto 0 auto;
  /* padding: 20px; */
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header, .footer {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

p, a, li, span, pre {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.button:hover {
  color: #bb1c00;
}

button {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

input, textarea {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notfound img {
  margin-top: 5vw;
  border-radius: 10%;
  max-width: 30vw;
}

@media screen and (max-width: 599px) {
  .container {
    width: 99%;
    margin: 65px auto 0 auto;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .container {
    width: 95%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 80vw;
  }
}