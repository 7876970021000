button[type="submit"] {
  display: block;
  width: 150px;
  padding: 10px;
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: rgb(255, 115, 64);
}

.upload-button-div {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}

.upload-form-hori {
  display: flex;
  justify-content: space-between;
  height: 120px;
}

.hori2 {
  margin-top: 20px;
}

.upload-form-hori > div {
  display: flex;
  justify-content: center;
  width: 48%;
  border: 1px solid rgb(255, 150, 112);
  border-radius: 7px;
}

.upload-form-hori-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6;
}

.upload-form-hori-div3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-steps {
  background-color: rgb(252, 104, 50);
  border: 1px solid rgb(255, 150, 112);
  border-radius: 100%;
  height: 21px;
  width: 21px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  align-content: center;
}

.upload-steps-title {
  margin-top: 12px;
  margin-left: 12px;
  font-weight: bold;
}

.upload-steps-title-star {
  color: darkred;
  font-weight: bold;
  margin-left: 7px;
}

.upload-form-hori-div-hori {
  display: flex;
}

.upload-form-hori-div-vert textarea {
  min-width: 260px;
  border: 1px solid rgb(201, 201, 201);
}

.upload-form-hori-div-vert-date {
  font-size: 22px;
  border: none;
  text-align: center;
  max-width: 120px;
}

.upload-form-hori-div-vert-select {
  font-size: 22px;
  border: none;
  max-width: 150px;
}

.upload-form-routes {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 0 0 0;
  min-height: 500px;
  width: 100%;
  border: 1px solid rgb(255, 150, 112);
  border-radius: 7px;
}

.upload-form-route-div {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.upload-form-route-div input,
textarea {
  margin: 5px 0;
  padding: 8px;
  background-color: rgba(243, 243, 243, 0.946);
  border: none;
  border-radius: 10px;
  outline: none;
}

.upload-form-memo:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.files-input {
  width: 250px;
  border: 1px solid rgb(201, 201, 201);
  border-radius: 7px;
  display: flex;
  padding: 15px;
  justify-content: space-around;
  background-color: rgba(243, 243, 243, 0.5);
}

.upload-form-route-div-wall {
  display: flex;
}

.route-types {
  display: flex;
  gap: 8px;
}

.route-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 7px;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.upload-steps-route-types {
  display: flex;
}

.upload-steps-route-types img {
  max-width: 46px;
  max-height: 55px;
}

.route-type p {
  margin-top: 5px;
  font-size: 14px;
}

.route-type.selected {
  border: 3px solid #007bff;
}

.difficulty-levels {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 7%;
}

.difficulty-levels button {
  flex: 1 1 calc(18% - 10px);
  box-sizing: border-box;
  font-weight: bold;
}

.difficulty-levels button,
.submit-button {
  background-color: rgb(255, 98, 0, 0.88);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
}

.attempts button {
  background-color: rgb(255, 98, 0, 0.88);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.wall-input {
  height: 25px;
  margin-top: 12px;
  margin-bottom: 10px;
  width: 125px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 7px;
  background-color: rgb(245, 245, 245, 0.8);
  outline: none;
}

.wall-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.difficulty-levels button:hover,
.attempts button:hover,
.submit-button:hover {
  background-color: rgb(255, 115, 64);
}

.attempts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  margin: -8px auto 0 auto;
}

.difficulty-levels button.selected {
  background-color: rgb(40, 130, 255);
}

.upload-form-route-div-summary {
  margin: 10px 20%;
  border-radius: 5px;
}

.upload-form-route-div-wall-summary {
  display: flex;
  gap: 5%;
  justify-content: space-between;
  align-items: center;
}

.upload-form-route-div-level {
  background-color: rgb(255, 98, 0);
  color: white;
  border-radius: 5px;
  padding: 7px 7px;
  font-weight: bold;
}

.add-record-div {
  display: flex;
  justify-content: right;
  margin: auto 5% 5% auto;
}

.add-record-button {
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 15%;
  width: 40px;
  height: 40px;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-record-button:hover {
  background-color: rgb(255, 115, 64);
}

.upload-form-details {
  border: 1px solid rgb(255, 150, 112);
  border-radius: 7px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.save-record-button {
  display: block;
  width: 150px;
  padding: 10px;
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-record-button:hover {
  background-color: rgb(255, 115, 64);
}

.upload-form {
  position: relative;
  width: 95%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.first-part, .second-part {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  transition: opacity 0.3s ease;
}

.second-part {
  margin-top: -20px;
  width: 100.5%;
}

.second-part-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 35px;
}

.hidden {
  display: none;
}

.cancel-button {
  display: block;
  width: 150px;
  padding: 10px;
  background-color: rgb(185, 185, 185);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: rgb(201, 201, 201);
}

.upload-form-details-one {
  display: flex;
}
