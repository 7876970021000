.personal-records-box-container {
  margin-top: 50px;
}

.personal-records-box-area {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.personal-records-box {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  margin: 20px 0 0px 0;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1000px;
}

h3 {
  text-align: center;
  color: darkred;
}

.personal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}

.personal-records {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin: 5px 0 0 10px;
}

.personal-records > p {
  margin: 0;
  padding-right: 20px;
}

.personal-records-memo {
  margin: 15px 0 10px 10px;
  font-style: italic;
  color: #555;
  display: flex;
  align-items: center;
}

.userData-details {
  margin-top: -5px;
}

.userData {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 18px;
}

.userData-img {
  max-height: 90px;
  align-content: center;
  margin-right: 15px;
}

.userData-img img {
  width: 60px;
  border-radius: 50%;
}

.user-name {
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: -15px;
}

.user-introduce {
  font-style: italic;
  color: rgb(80, 80, 80);
}

.personal-records-summary {
  padding: 10px;
  background-color: rgb(255, 243, 236);
  border: 2px solid #ffd6c0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 50;
}

.personal-records-summary p {
  margin: 0 10px;
  font-weight: bold;
  font-size: 16px;
  color: #333;
  flex: 1;
}

.personal-records-route-types {
  display: flex;
  align-items: center;
  gap: 8px;
}

.route-types img {
  width: 30px;
  height: 38px;
  margin-right: 5px;
}

.personal-records-details {
  margin-left: 1.5px;
  margin-top: -2px;
  padding: 10px;
  border-left: 2px solid #ffc3a5;
  border-radius: 5px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}

.personal-records-details .personal-records {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.personal-records-details .personal-records > p {
  margin: 0;
  padding-right: 20px;
}

.personal-records-details .personal-records > .route-types {
  margin-top: 0;
}

.personal-records-details > div {
  margin-bottom: 15px;
}

.personal-records-details > div:last-child {
  margin-bottom: 0;
}

.personal-records-details p {
  margin: 5px 0;
}

.personal-records-files {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.personal-records-files img, .personal-records-files video {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 5px;
}

.file-container {
  max-width: 320px;
  max-height: 240px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.file-content {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.personal-records-box:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.personal-records-box:last-child {
  margin-bottom: 0;
}

.personal-records-details > div {
  margin-bottom: 15px;
}

.personal-records-details > div:last-child {
  margin-bottom: 0;
}

.personal-records-details p {
  margin: 5px 0;
}

.btn-personal-add-record {
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  /* position: fixed;
  bottom: 320px;
  right: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* margin-top: 200px; */
}

.btn-personal-add-record:hover {
  background-color: rgb(255, 111, 22);
}

.personal-add-record {
  display: flex;
  justify-content: flex-end;
}

.personal-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
}

.personal-charts > div {
  flex: 0 0 48%;
  box-sizing: border-box;
  margin: 0 auto;
  /* margin-bottom: 20px; */
  /* border: 1px solid black; */
  /* margin-left: -15px; */
  padding-right: 15px;
  padding-left: -50px;
}

.delete-button-area {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -60px;
}

.delete-button-area2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -45px;
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  color: rgb(66, 15, 15, 0.7);
  border-radius: 5px;
  border: 1px solid rgb(165, 165, 165, 0.8);
  background-color: #ffffff;
  cursor: pointer;
}

.delete-button:hover {
  color: rgb(80, 31, 0);
}

@media screen and (max-width: 599px) {
  .personal-records-summary {
    width: 93.5%;
  }

  .personal-records-summary > p {
    font-size: 15px;
    margin: 0 -5px;
  }
  
  .personal-records-box {
    min-width: 320px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .personal-records-summary {
    width: 95.7%;
  }

  .personal-records-box {
    min-width: 560px;
  }
}

@media screen and (min-width: 1024px) {
  .personal-records-summary {
    width: 97.3%;
  }

  .personal-records-box {
    min-width: 880px;
  }
}