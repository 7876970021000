.message-box {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeinout 5s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.message-error-box {
  background-color: #af4c4c;
}

.message-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
