.header {
  position: fixed;
  top: 0;
  width: 100vw;
  min-width: 300px;
  background-color: #ffffff;
  color: #fff;
  text-align: center;
  padding: 10px 0px 10px 15px;
  z-index: 1000;
}

.logo {
  max-height: 65px;
  margin-right: 10px;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-links {
  display: flex;
  align-items: center;
}

.menu-login {
  margin-right: 10px;
}

.button {
  font-size: 16px;
  margin-left: 20px;
  text-decoration: none;
  color: rgb(67, 67, 67);
}

.button.active {
  color: #bb1c00;
  font-weight: bold;
}

.header-menu > div {
  display: flex;
  align-items: center;
}

.login-button {
  padding: 5px 25px;
  border: 1px solid rgb(255, 98, 0);
  border-radius: 5px;
  background-color: rgb(255, 98, 0);
  color: white;
  margin-right: 30px;
}

.login-button:hover {
  background-color: rgb(255, 115, 0);
  border: 1px solid rgb(255, 115, 0);
}

.logout-button {
  background-color: white;
  border: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  padding: 5px 25px;
  margin-right: 30px;
}

.m-logout-button {
  background-color: white;
  border: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  padding: 5px 25px;
  margin-right: 20px;
}

.menu-container-box {
  position: fixed;
  width: 330px;
  padding: 20px 0;
  right: 38px;
  top: 100px;
  z-index: 100;
  overflow: hidden;
  background-color: rgb(0, 0, 0, 0.88);
  transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
}

.menu-container-box.collapsed {
  max-height: 0;
  padding: 0;
}

.menu-container-box.expanded {
  max-height: 500px;
}

.menu-button svg {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  color: black;
}

.menu-button {
  padding: 5px 5px;
  border: none;
  background-color: white;
  margin-right: 10px;
}

.menu-button svg:hover {
  color: #931600;
  cursor: pointer;
}

.m-menu-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.m-login-button {
  padding: 5px 50px;
  border: 1px solid rgb(255, 98, 0);
  border-radius: 5px;
  background-color: rgb(255, 98, 0);
  color: white;
  text-decoration: none;
}
  
.m-login-button:hover {
  background-color: rgb(255, 115, 0);
  border: 1px solid rgb(255, 115, 0);
}

.m-button {
  font-size: 18px;
  text-decoration: none;
  color: rgb(255, 255, 255, 0.8);
}
  
.m-button.active {
  color: #ff8360;
  font-weight: bold;
}

.m-button:hover {
  color: #ffbdb1;
  cursor: pointer;
}