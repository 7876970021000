.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-form-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-email,
.input-name,
.input-password {
  margin: 12px 0;
  padding: 12px;
  background-color: rgba(243, 243, 243, 0.946);
  border: none;
  border-radius: 10px;
  max-width: 600px;
  width: 65vw;
}

h1, p {
  text-align: center;
}

h1 {
  color: darkred;
}

.signup-submit {
    margin-top: 10px;
    width: 150px;
    height: 50px;
    padding: 10px 20px;
    border: 1px solid rgb(255, 98, 0);
    border-radius: 5px;
    background-color: rgb(255, 98, 0);
    color: white;
    font-size: 18px;
}

.signup-submit:hover {
    background-color: rgb(255, 115, 0);
    border: 1px solid rgb(255, 115, 0);
}

.signup-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
}