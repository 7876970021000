body {
  font-family: Arial, sans-serif;
}

.friend-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: 300px;
}

.friend-item {
  display: flex;
  align-items: center;
  width: 85vmin;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.friend-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.friend-name {
  font-weight: bold;
  margin-right: auto;
}

.btn-view,
.btn-chat {
  background-color: rgb(40, 130, 255);
  display: block;
  width: 90px;
  padding: 5px;
  margin-left: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-view:hover,
.btn-chat:hover {
  background-color: #0961c0;
}

.btn-add-friend {
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 30px;
}

.add-record-button {
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 15%;
  width: 40px;
  height: 40px;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 10px auto; */
}

.btn-add-friend:hover {
  background-color: rgb(255, 111, 22);
}

.add-friend-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-friend-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-friend-container h2 {
  margin-bottom: 20px;
}

.add-friend-container input {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 7px;
  background-color: rgb(245, 245, 245, 0.5);
  outline: none;
}

.add-friend-container > input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
/* .add-friend-container button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-friend-container button:hover {
    background-color: #0056b3;
  } */

.cancel-add-friend-button {
  display: block;
  width: 80px;
  padding: 10px;
  background-color: rgb(185, 185, 185);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-add-friend-button:hover {
  background-color: rgb(201, 201, 201);
}

.add-friend-buttons {
  display: flex;
  gap: 15px;
}

.add-friend-button {
  display: block;
  width: 80px;
  padding: 10px;
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-friend-button:hover {
  background-color: rgb(255, 115, 64);
}

.friend-add-friend {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 100px;
}