.custom-page {
  padding: 20px 0;
  text-align: center;
}

.walls-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.wall-item {
  width: 300px;
  height: 400px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.wall-item:hover {
  background-color: rgb(240, 247, 255);
  color: #0961c0;
  transform: scale(1.05) translateY(-10px);
}

.wall-item > h4 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}

.wall-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.custom-item-details {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 750px;
  /* margin: 0 auto; */
  align-items: center;
  /* position: relative; */
  margin: 20px auto;
  background-color: #f9f9f9;
  padding: 20px 15px 40px 15px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-item-details > img,
canvas {
  max-width: 315px;
  height: auto;
}

.custom-item-details-h3-div {
  background-color: rgb(240, 247, 255, 0.1);
  margin-bottom: -12px;
  padding: 0 15px;
  border: 2px solid rgb(138, 156, 255, 0.7);
  border-radius: 7px;
  width: 90%;
  max-width: 285px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.custom-item-details-h3-div > h3 {
  color: #0961c0;
  width: 100px;
}

.custom-item-details > p {
  margin-bottom: 0;
  margin-top: 20px;
}

.images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.route-output img,
.route-details img {
  max-width: 315px;
  transition: transform 0.3s ease;
}

.route-output {
  padding-top: 5px;
}

.custom-add-button,
.process-button,
.process-save-button,
.process-edit-button,
.eraser-button,
.confirm-button
/* , .share-custom-button  */
{
  display: block;
  width: 150px;
  padding: 10px;
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.complete-custom-button {
  display: block;
  width: 150px;
  padding: 10px;
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0 5px 0;
}

.custom-add-button:hover,
.process-button:hover,
.process-save-button:hover,
.process-edit-button:hover,
.eraser-button:hover,
.confirm-button:hover
/* , .share-custom-button:hover  */
{
  background-color: rgb(255, 142, 72);
}

.route-details-data {
  /* margin-top: -10px; */
  margin-bottom: 10px;
}

.custom-share-button {
  margin: 0 0 -55px 0;
  /* padding-top: 0px; */
  width: 315px;
  display: flex;
  justify-content: flex-end;
}

.custom-share-button > button {
  cursor: pointer;
  border: none;
  width: 80px;
  height: 40px;
  background-color: transparent;
  font-size: 20px;
  /* margin-top: 10px; */
}

.custom-share-button > button:hover {
  color: #0961c0;
  font-size: 21px;
  padding-bottom: 2px;
}

.return-button {
  display: block;
  width: 80px;
  height: 30px;
  padding: 3px;
  background-color: rgba(192, 192, 192, 0.887);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* margin-left: 20px; */
}

.return-button:hover {
  background-color: rgb(183, 183, 183);
}

.return-button > img {
  width: 25px;
}

.custom-textarea {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: rgba(243, 243, 243, 0.946);
}

.custom-input-name {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: rgba(243, 243, 243, 0.946);
  outline: none;
}

.custom-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.custom-input-name:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.route-types {
  display: flex;
  align-items: center;
  justify-content: center;
}

.route-types-custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.route-type-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* padding-left: 10px; */
}

.route-type-custom.selected {
  border: 2px solid #007bff;
  border-radius: 5px;
}

.route-type-custom img {
  width: 39px;
  height: 50px;
  margin: 3px;
}

.route-list-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.routes-list > h4 {
  margin-left: 10px;
}

.routes-list {
  width: 90%;
  max-width: 800px;
  text-align: left;
  margin-top: 10px;
}

.route-item {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.route-item.selected {
  background-color: rgb(240, 247, 255, 0.5);
  color: #0961c0;
}

.route-item:hover {
  background-color: rgb(240, 247, 255);
}

.route-details {
  text-align: left;
  margin: 20px auto;
  background-color: #f9f9f9;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px 40px 15px;
  width: 95%;
  max-width: 750px;
}

.hidden {
  display: none;
}

.adding-custom-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}

.adding-custom-area > p {
  color: gray;
  font-size: 15px;
  margin-bottom: 0px;
}

.custom-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  min-width: 100%;
  padding-right: 20px;
}

.edit-or-save {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
}

.complete-count-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-can-add-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-memo {
  margin-top: 10px;
  font-style: italic;
  color: #555;
}

.route-details-data-name {
  font-weight: bold;
  font-size: 17px;
}

.route-details > button {
  margin-top: 20px;
  margin-bottom: -10px;
}

.custom-submit-button-div {
  display: flex;
  justify-content: center;
  padding-left: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media screen and (max-width: 599px) {
  .adding-custom-area > p {
    margin: 0 20px;
  }

  .adding-custom-buttons > button {
    width: 135px;
  }

  .edit-or-save > button {
    width: 135px;
  }
}

/* @media screen and (min-width: 600px) and (max-width: 1023px) {

} */

/* @media screen and (min-width: 1024px) {
 
} */