.explore-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.record-card {
  width: 90vmin;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.record-header {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background-color: #f9f9f9;
  justify-content: space-between;
}

.record-header-basic {
  display: flex;
  align-items: center;
}

.user-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-info-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 55px;
  margin-right: 5px;
}

.record-header-wall {
  font-style: italic;
  color: #555;
}

.record-header-wall > p {
  margin-top: 0px;
  margin-right: 3px;
}

.explore-date {
  margin: 5px 0 2px 0;
}

.explore-user-name {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  margin-top: 3px;
}

.gym-name {
  /* color: #888; */
  margin: 0;
}

.record-content {
  padding: 10px;
  height: 100%;
}

.image-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slide {
  width: 100%;
  display: none;
}

.slide.active {
  display: flex;
  justify-content: center;
}

.pagination {
  text-align: center;
  padding: 10px 0;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: rgb(40, 130, 255);
}

/* .active {
  background-color: #436abf;
} */

.record-level {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.record-level p {
  margin-top: 0;
  margin-bottom: 0;
}

.record-times {
  font-size: 15px;
  color: rgb(55, 55, 55);
}

.record-footer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9f9;
}

.record-footer button {
  padding: 0 5px;
  width: 20vmin;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(164, 164, 164, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(203, 193, 185, 0.5);
  cursor: pointer;
  height: 100%;
}

.record-footer button:hover {
  margin: -1px 0;
}

.record-footer1 {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.comment-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  max-height: calc(3 * 1.7em);
  overflow-y: scroll;
}

.comment-list p {
  margin: 0;
  text-align: left;
}

.comment-list::-webkit-scrollbar {
  width: 8px;
}

.comment-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.comment-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.comment-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.comment-input {
  display: flex;
  align-items: center;
  margin: 0 8px;
}

.comment-input input {
  flex: 1;
  padding: 5px;
  margin-right: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
}

.comment-input input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.comment-input button {
  background-color: rgb(40, 130, 255);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.comment-input button:hover {
  background-color: #0961c0;
}

.file-container-explore {
  width: 100%;
  height: 50vmin;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.file-content-explore {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.btn-explore-add-record {
  background-color: rgb(255, 98, 0);
  color: white;
  border: none;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 320px;
  right: 120px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-explore-add-record:hover {
  background-color: rgb(255, 111, 22);
}

.record-memo {
    margin-top: 10px;
    font-style: italic;
    color: #555;
    margin: 0 10px;
}

.filter-container-box {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 105px;
  bottom: 150px;
  z-index: 100;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
}

.filter-container-box.collapsed {
  max-height: 0;
  padding: 0;
}

.filter-container-box.expanded {
  max-height: 500px;
}

.filter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  width: 30vmin;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(40, 130, 255, 0.8);
  border-radius: 3px;
  padding-bottom: 20px;
}

.filter-container select {
  margin: 0 10px;
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
}

.filter-container select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.filter-container > p {
  margin-bottom: -5px;
  font-weight: bold;
  color: rgb(27, 94, 187);
}

.filter-container > button {
  width: 88%;
  margin: 0 auto;
  padding: 5px;
  background-color: rgb(40, 130, 255);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.explore-space-div {
  margin: 0 10px;
  border-bottom: 1px solid rgba(20, 102, 216, 0.7);
}

.filter-container button:hover {
  background-color: #0961c0;
}

.toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 110;
  border-radius: 5px;
  margin-top: 10px;
  position: fixed;
  right: 150px;
  bottom: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button:hover {
  background-color: #0961c0;
}

.toggle-button svg {
  width: 20px;
  height: 20px;
}

.likes button, .comments button, .share button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 30px;
  font-size: 16px;
}

.likes button:hover, .comments button:hover, .share button:hover {
  color: #007bff;
}

.likes button, .comments button, .share button {
  color: #032a53;
}

@media screen and (max-width: 599px) {
  .toggle-button {
    position: fixed;
    right: 30px;
    bottom: 70px;
  }
  
  .filter-container-box {
    right: 30px;
    bottom: 100px;
  }

  .filter-container {
    width: 50vmin;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .toggle-button {
    position: fixed;
    right: 55px;
    bottom: 70px;
  }
  
  .filter-container-box {
    right: 55px;
    bottom: 100px;
  }

  .filter-container {
    width: 35vmin;
  }
}

/* @media screen and (min-width: 1024px) {
 
} */