.home-signup-button {
  width: 250px;
  height: 55px;
  padding: 10px 20px;
  border: 1px solid rgb(255, 98, 0);
  border-radius: 5px;
  background-color: rgb(255, 98, 0);
  color: white;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}

.home-signup-button:hover {
  background-color: rgb(255, 115, 0);
  border: 1px solid rgb(255, 115, 0);
  font-size: 20;
}

.home1-signup-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page img {
  width: 110%;
}

@media screen and (max-width: 599px) {
  .home1-signup-area {
    margin-top: -5px;
    margin-bottom: 20px;
  }
  
  .home-signup-button {
    width: 200px;
    height: 45px;
    font-size: 16px;
  }

  .home-signup-button:hover {
    font-size: 18px;
  }
}